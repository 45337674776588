import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
    Avatar,
    Button,
    Card,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    ListItem, ListItemButton,
    Stack,
    styled,
    Typography,
    useMediaQuery
} from "@mui/material";
import themeMuiV5 from "components/common/ThemeMuiV5";
import { navigate, useIntl } from "gatsby-plugin-intl";
import React, { Fragment, useEffect, useState } from "react";
import academicService from 'services/api/academicService';
import mailService from 'services/api/mailService';
import { getUser } from 'services/api/requests';
import { containsRole } from 'services/auth';
import {
    CvcLogoBiblio,
    CvcLogoGoogleScholar,
    CvcLogoLinkedIn,
    CvcLogoOrcid,
    CvcLogoResearchGate,
    CvcLogoTwitter,
    CvcLogoWebPage,
    CvcLogoWithLink
} from "ui/CvcLogoWithLink";
import { ROLE, universities } from "utils/constants/constants";
import { AcademicDetailTitle, AcademicPublications } from "./AcademicDetailComponent";
import { Message } from './MessageComponent';


const AcademicDetail = ({ academic }) => {

    const intl = useIntl();

    let [image, setImage] = useState("");
    let [imageAlt, setImageAlt] = useState({});
    let [photo, setPhoto] = useState("");

    const [user, setUser] = useState(null);
    const [openMessage, setOpenMessage] = useState(false);
    const [selectedMailId, setSelectedMailId] = useState(null);
    const [enableMessage, setEnableMessage] = useState(false);

    const [lstMoreInformation, setLstMoreInformation] = useState([]);
    const [sitePublications, setSitePublications] = useState();
    const [lstArticlesInFlux, setLstArticlesInFlux] = useState([]);
    const [lstMessage, setLstMessage] = useState([]);
    const [loadingPublux, setLoadingPubFlux] = useState(false);

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    });

    //Passage de moreInformation en bas de page quand on passe sous le breakpoint md
    const isUnderMd = useMediaQuery(themeMuiV5.breakpoints.down('md'))
    const configMoreInformation = isUnderMd ?
        {
            paddingLeft: themeMuiV5.spacing(0),
            paddingTop: themeMuiV5.spacing(3)
        }
        :
        { paddingLeft: themeMuiV5.spacing(3) };


    class InfoLink {
        constructor(code, link, logo, descr) {
            this.code = code;
            this.link = link;
            this.logo = logo;
            this.descr = descr;
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                let user = await getUser();
                
                setUser(user.data);
                await refreshMailStatus();
                await getRSSArticles();
            } catch (error) {
            }
        };

        fetchData();

        let univ = universities.find(univ => univ.acronym === academic?.universityAcronym);
        if (typeof univ !== "undefined" && univ !== null) {
            setImage(univ.img);
            setImageAlt(univ.name);
        } else {
            setImage(null);
        }

        if (typeof academic?.photo?.stream == "undefined" || academic?.photo?.stream == null || academic?.photo?.stream?.length == 0) {
            setPhoto('/photo/academicDefaultProfil.png');
        } else {
            setPhoto(`data:image/jpeg;base64,` + academic.photo.stream);
        }

        if (academic && academic?.informationLinks?.length > 0) {
            moreInformation();
            publications();
        }

    }, [academic]);

    function moreInformation() {
        let lstMoreInformation = createLinkInformation('ORC', CvcLogoOrcid, 'https://orcid.org/', null, null);
        lstMoreInformation = createLinkInformation('PROWB', CvcLogoWebPage, null, intl.formatMessage({ id: 'academic.link.pro.webpage' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('PERWB', CvcLogoWebPage, null, intl.formatMessage({ id: 'academic.link.perso.webpage' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('PUBWB', CvcLogoBiblio, null, intl.formatMessage({ id: 'academic.link.bibliography' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('GS', CvcLogoGoogleScholar, null, intl.formatMessage({ id: 'academic.link.googleScholar' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('RG', CvcLogoResearchGate, null, intl.formatMessage({ id: 'academic.link.researchGate.profile' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('LI', CvcLogoLinkedIn, null, intl.formatMessage({ id: 'academic.link.linkedIn' }), lstMoreInformation);
        lstMoreInformation = createLinkInformation('TW', CvcLogoTwitter, 'https://twitter.com/', null, lstMoreInformation);

        setLstMoreInformation(lstMoreInformation);
    }

    function publications() {
        let lstPublicationAcademic = createLinkInformation('PUBWB', null, null, `Please follow this link to see all publications of ${academic.firstName} ${academic.lastName}`, null);
        // lstPublicationAcademic = createLinkInformation('PUBFL', null, null, null, lstPublicationAcademic)

        setSitePublications((!!lstPublicationAcademic && lstPublicationAcademic.length > 0) ? lstPublicationAcademic[0] : null);
    }

    function createLinkInformation(code, logo, prefixeUrl, descr, lstOrigin) {
        let lstPublicationReturn = (lstOrigin) ? lstOrigin : [];

        let info = academic.informationLinks.find(info => info.type == code);
        if (info && info.url) {
            let infoLink = new InfoLink(info.type, (prefixeUrl) ? prefixeUrl + info.url : info.url, logo, (descr) ? descr : info.url);
            lstPublicationReturn.push(infoLink);
        }

        return lstPublicationReturn
    }

    function navigateClickSearch(e, search, value) {
        e.stopPropagation()
        navigate(`/faculty/search${search}/` + value)
    }

    const handleClickOpenMessage = () => {
        setOpenMessage(true);
    };

    const refreshMailStatus = async () => {
        let canUserSendMail = await mailService.canUserContactAcademic(academic.academicId)
        setEnableMessage(canUserSendMail.data);
        let mails = await mailService.selectSentToAcademicId(academic.academicId)
        setLstMessage(mails.data);
    }

    const getRSSArticles = async () => {
        if (academic && academic.userId) {
            setLoadingPubFlux(true);
            let lstArticles = await academicService.getRSSArticlesByUserId(academic.userId);
            setLstArticlesInFlux(lstArticles.data);
            setLoadingPubFlux(false);
        }
    }

    const handleCloseMessage = async () => {
        setOpenMessage(false);
        await refreshMailStatus();
    };

    const handleClickOpenReadMessage = (mailId) => {
        setSelectedMailId(mailId);
    };

    const handleCloseReadMessage = () => {
        setSelectedMailId(null);
    };

    useEffect(() => {

    }, [openMessage]);

    return (
        <Card
            sx={{
                marginBottom: themeMuiV5.spacing(2),
                padding: themeMuiV5.spacing(1),
            }}
        >
            <Grid container>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Stack>
                        <Grid item container xs={12}>
                            <Grid item xs="auto" pr={1}>
                                <Typography component="div">
                                    <Avatar alt={"photo " + academic?.lastName} src={photo}
                                        sx={{ width: 100, height: 100 }} />
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Grid item container xs={12} justifyContent='center'>
                                    <Grid item xs={8}>
                                        <Typography variant="h6" component="h2">
                                            {academic?.lastName} {academic?.firstName}
                                            {((user?.cvcUser?.userId == academic?.userId && containsRole([ROLE.academic])) ||
                                                (process.env.SHOW_ADMIN && containsRole([ROLE.admin])
                                                    || (process.env.SHOW_ADMIN && containsRole([ROLE.academics_manager, ROLE.admin_university]) && user?.university?.universityId == academic?.universityId)
                                                )) ? (
                                                <Fragment>
                                                    <IconButton
                                                        aria-label={intl.formatMessage({ id: "academics.detail.updateProfile" })}
                                                        color="primary"
                                                        onClick={() => navigate(`/back-office/faculty/edit/${academic?.academicId}`)}>
                                                        <EditOutlinedIcon color="primary" />
                                                    </IconButton>
                                                </Fragment>
                                            ) : ''
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid item container xs={12}
                                            direction="row"
                                            justifyContent="flex-end"
                                        >
                                            {(image ?
                                                <Typography component="div">
                                                    <IconButton
                                                        aria-label={intl.formatMessage({ id: "academics.detail.university" })}
                                                        color="primary"
                                                        onClick={(e) => navigateClickSearch(e, 'University', academic?.universityId)}
                                                        sx={{ paddingBottom: 0, paddingTop: 0 }}
                                                    >
                                                        <Img src={image}
                                                            alt={"logo " + imageAlt}
                                                            sx={{
                                                                maxHeight: '35px'
                                                                , maxWidth: '100px'
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Typography> : ''
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            component="span"
                                            align="justify">
                                            {academic?.titles && academic?.titles?.length > 0 ? academic?.titles.sort().map((title, i) =>
                                            (
                                                <p key={'title-' + i}
                                                    style={{ display: 'inline' }}>{title.label}{(i < academic.titles.length - 1) ? ', ' : ''}</p>
                                            )
                                            ) : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid item container xs={12}
                                            direction="row"
                                            justifyContent="flex-end"
                                        >
                                            <Link
                                                href='#'
                                                key={`researchCenter-${academic?.academicId}`}
                                                variant="body2"
                                                align='right'
                                                underline='hover'
                                                onClick={(e) => navigateClickSearch(e, 'UniversityUnit', academic?.universityUnitId)}
                                            >
                                                {academic?.universityUnitName}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography component="p" variant="body2" align='justify' pt={1}
                            style={{ whiteSpace: 'pre-line' }}>
                            {academic?.description}
                        </Typography>
                        {academic?.fieldsOfExpertise && academic?.fieldsOfExpertise?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.fieldsExpertise" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.fieldsOfExpertise?.sort().map((expertise, i) =>
                                    (
                                        <Chip
                                            key={'expertise-' + i}
                                            label={expertise.label}
                                            onClick={(e) => navigateClickSearch(e, 'Expertise', expertise.value)}
                                        />
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {academic?.academicDisciplines && academic?.academicDisciplines?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.disciplines" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.academicDisciplines?.sort().map((discipline, i) =>
                                    (
                                        <Chip
                                            key={'discipline-' + i}
                                            label={discipline.label}
                                            onClick={(e) => navigateClickSearch(e, 'Discipline', discipline.value)}
                                        />
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {academic?.countriesOfResearch && academic?.countriesOfResearch?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.countriesResearch" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.countriesOfResearch?.sort().map((countrieResearch, i) =>
                                    (
                                        <Chip
                                            key={'countrieResearch-' + i}
                                            label={countrieResearch.label}
                                            onClick={(e) => navigateClickSearch(e, 'CountrieResearch', countrieResearch.value)}
                                        />
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {academic?.workingLanguages && academic?.workingLanguages?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.workingLanguages" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.workingLanguages?.sort().map((workingLanguage, i) =>
                                    (
                                        <Chip
                                            key={'workingLanguage-' + i}
                                            label={workingLanguage.label}
                                            onClick={(e) => navigateClickSearch(e, 'WorkingLanguages', workingLanguage.value)}
                                        />
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {!!sitePublications ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.publications" />
                                <Typography component="span" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    <CvcLogoWithLink
                                        code={sitePublications.code}
                                        link={sitePublications.link}
                                        logo={null}
                                        descr={sitePublications.descr}
                                        key={`${sitePublications.code}`}
                                        id={`${sitePublications.code}`} />
                                </Typography>
                            </Fragment>) : ''}
                        {(loadingPublux) ?
                            <CircularProgress /> :
                            (lstArticlesInFlux && lstArticlesInFlux.length > 0) ?
                                (<Fragment>
                                    <Typography sx={{ paddingTop: themeMuiV5.spacing(1) }}>{intl.formatMessage({ id: "academic.publication.flux.title" })}</Typography>
                                    {lstArticlesInFlux.map((publication, id) => {
                                        return (
                                            (id <= 10) ? (
                                                <AcademicPublications id={id} publication={publication} key={`academicpublications${id}`} />
                                            ) : ""
                                        );
                                    })}
                                </Fragment>) : ""}
                        {academic?.researchProjects && academic?.researchProjects?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.researchProjects" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.researchProjects?.sort().map((researchProject, i) =>
                                    (
                                        <Typography component="p"
                                            key={`researchProject-${i}`}
                                        >
                                            - {researchProject.label}
                                        </Typography>
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {academic?.civicaCourseLinks && academic?.civicaCourseLinks?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.civicaTeaching" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.civicaCourseLinks?.sort().map((courseLinks, i) =>
                                    (
                                        <Link
                                            href='#'
                                            key={`courseLinks-${i}`}
                                            variant="body2"
                                            align='right'
                                            onClick={() => { navigate(courseLinks.url) }}
                                        >
                                            {courseLinks.description}
                                        </Link>
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                        {academic?.academics && academic?.academics?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.academicsInterest" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}>
                                    {academic?.academics?.sort().map((academic, i) =>
                                    (
                                        <Chip
                                            key={'academicsInterest-' + i}
                                            label={academic?.label}
                                        />
                                    ))
                                    }
                                </Typography>
                            </Fragment>) : ''}
                    </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Stack sx={configMoreInformation} >

                        {lstMoreInformation && lstMoreInformation.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.moreInformation" paddingTop={false}
                                    maringBottom={true} />
                                {lstMoreInformation?.sort().map((info, i) =>
                                    <CvcLogoWithLink code={info.code} link={info.link} logo={info.logo}
                                        descr={info.descr} key={`${info.code}-${i}`}
                                        id={`${info.code}-${i}`} />
                                )}
                            </Fragment>)
                            : ''

                        }

                        {(academic?.email) || 1 == 1 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.contact.titre" />
                                <Typography component="div" sx={{ paddingTop: themeMuiV5.spacing(1) }}
                                    align={"center"}>
                                    <Button variant="contained" onClick={handleClickOpenMessage}
                                        disabled={!enableMessage}>{intl.formatMessage({ id: "academics.detail.contact.write" })}</Button>
                                    <Message open={openMessage} handleClose={handleCloseMessage} academic={academic}
                                        photo={photo} />
                                </Typography>
                            </Fragment>) : ''
                        }

                        {lstMessage && lstMessage?.length > 0 ?
                            (<Fragment>
                                <AcademicDetailTitle title="academics.detail.contact.messages" />
                                {lstMessage?.sort().map((message, i) =>
                                (
                                    <Typography component="div" key={`divMessage-${i}`}>
                                        <ListItem dense={true}>
                                            <ListItemButton component="a"
                                                onClick={() => handleClickOpenReadMessage(message.mailId)}
                                                key={`messageList-${i}`}> {new Date(message.date).toLocaleDateString(intl.locale) + ' - ' + message.subject}</ListItemButton>
                                        </ListItem>
                                        <Message open={message.mailId === selectedMailId}
                                            handleClose={handleCloseReadMessage} academic={academic}
                                            photo={photo} message={message} key={`message-${i}`} />
                                    </Typography>
                                ))
                                }
                            </Fragment>) : ''}

                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}
export default AcademicDetail;
import React, { useEffect, useRef, useState } from 'react';

import { navigate, useIntl } from 'gatsby-plugin-intl';

import Seo from 'components/common/Seo';
import Layout from 'components/layout/Layout';
import { useForm } from 'react-hook-form';
import academicService from 'services/api/academicService';
import listOfValueService from 'services/api/listOfValueService';
import WelcomeAcademics from '../common/WelcomeAcademics';
import AcademicsList from './components/AcademicsList';
import { AcademicsPagination } from './components/AcademicsListComponents';
import AcademicsListSearchEngine from './components/AcademicsListSearchEngine';
import { getUser } from 'services/api/requests';
import * as searchStyles from 'styles/search-page.module.scss';


const AcademicsPage = ({ searchId = null , typeOfSearch = null}) => {

    const intl = useIntl();
    const pageSize = 25;

    const ALL = 'All';
    const defaultAlreadyContacted = "";

    let defaultValues = {
        textFilter: "",
        academicsUniversity: "",
        academicUnit: "",
        expertises: [],
        disciplines: [],
        countriesResearch: [],
        workingLanguage: [],
        alreadyContacted: defaultAlreadyContacted,
    };

    const initDefaultValuesList = {
        expertise: null,
        discipline: null,
        countrieResearch: null,
        workingLanguage: null,
        university: null,
        researchCenter: null,
    };

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    const [user, setUser] = useState();
    const [rows, setRows] = useState([]);
    const [defaultValueList, setDefaultValueList] = useState(initDefaultValuesList);
    const [academicsLetter, setAcademicsLetter] = useState([]);    
    const [searchLetter, setSearchLetter] = useState(ALL);

    const methods = useForm({ defaultValues: defaultValues });
    const searchCriteria = useRef({});

    const updateUrl = () => {
        const searchCriterias = searchCriteria.current;
        removeEmptyCriterias(searchCriterias);
        mapObjectCriterias(searchCriterias);
        const urlSearchParams = new URLSearchParams(searchCriterias);
        const searchQueryString = urlSearchParams && urlSearchParams.size > 0 ? ('?'+urlSearchParams.toString()):'';
        const urlToGo = "/"+intl.locale+'/faculty'+searchQueryString
        window.location.replace(urlToGo);
    }

    const removeEmptyCriterias = (searchCriterias) => {
        for (const [key, value] of Object.entries(searchCriterias)) {
            if(value == null || value == undefined || value == '' || value.length == 0){
                delete searchCriterias[key];
            }            
        }
    }

    const mapObjectCriterias = (searchCriterias) => {
        for (const [key, value] of Object.entries(searchCriterias)) {
            if(searchCriterias[key] instanceof Array && searchCriterias[key][0] != null 
                && typeof searchCriterias[key][0] === 'object'){
                    searchCriterias[key] = searchCriterias[key].map( item => { return item['value'] } );
            }            
        }
    }

    const resetForm = () => {
        methods.reset();
        if (!!searchId) {
            navigate(`/faculty`);
        } else {
            searchCriteria.current = defaultValues;
            updateUrl();
            loadServerRows(1, []);
        }
    };

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        updateUrl();
        loadServerRows(1, []);
    }

    const onClickSearchLetter = (e, letter) => {
        setSearchLetter(letter);
        loadAcademicsRows(1, [], letter);
        resetPagination();
    }

    const loadServerRows = async (page, sortModel) => {
        setLoading(true);
        const data = await academicService.searchAcademicsAndListFirstLetterNameFOByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel,
            user?.userId
        );
        let content = data.data;
        let mapContent = content.content;
        setAcademicsLetter(mapContent.LIST_NAME_FIRST_LETTER);
        setRows(mapContent.ACADEMICS);
        setTotalRows(content.totalSize);
        resetPagination();
        setSearchLetter(ALL);
        setLoading(false);
    };

    const loadExpertiseById = async (expertiseIdParam) => {
        const type = 'expertises';

        setLoading(true);

        const data = await listOfValueService.searchExpertiseById(
            expertiseIdParam
        );
        let content = data.data;
        defaultValueList.expertise=content;
        setDefaultValueList(defaultValueList);
        if (content && content != null) {
            let expertisesMethod = methods.getValues(type);
            defaultValues.expertises = [content];
            let index = expertisesMethod.findIndex(element => element.value == defaultValueList.expertise.value);
            if (index < 0) {
                expertisesMethod.push(content)
                methods.setValue(type, expertisesMethod);
            }
        }
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    const loadDisciplineById = async (searchIdParam) => {
        const type = 'disciplines';
        setLoading(true);

        const data = await listOfValueService.searchDisciplineById(
            searchIdParam
        );
        let content = data.data;
        defaultValueList.discipline=content;
        setDefaultValueList(defaultValueList);
        
        if (content && content != null) {
            let disciplineValues = methods.getValues(type);
            defaultValues.disciplines = [content];
            let index = disciplineValues.findIndex(element => element.value == defaultValueList.discipline.value);
            if (index < 0) {
                disciplineValues.push(content)
                methods.setValue(type, disciplineValues);
            }
        }
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    const loadCountriesResearchById = async (searchIdParam) => {
        const type = 'countriesResearch';
        setLoading(true);

        const data = await listOfValueService.searchCountrieResearchById(
            searchIdParam
        );
        let content = data.data;
        defaultValueList.countrieResearch=content;
        setDefaultValueList(defaultValueList);
        if (content && content != null) {
            let currentValues = methods.getValues(type);
            defaultValues.countriesResearch = [content];
            let index = currentValues.findIndex(element => element.value == defaultValueList.countrieResearch.value);
            if (index < 0) {
                currentValues.push(content)
                methods.setValue(type, currentValues);
            }
        }
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    const loadWorkingLanguageById = async (searchIdParam) => {
        const type = 'workingLanguage';
        setLoading(true);

        const data = await listOfValueService.searchWorkingLanguagesById(
            searchIdParam
        );
        let content = data.data;
        defaultValueList.workingLanguage=content;
        setDefaultValueList(defaultValueList);
        if (content && content != null) {
            let currentValues = methods.getValues(type);
            defaultValues.workingLanguage = [content];
            let index = currentValues.findIndex(element => element.value == defaultValueList.workingLanguage.value);
            if (index < 0) {
                currentValues.push(content)
                methods.setValue(type, currentValues);
            }
        }
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    const loadUniversityById = async (searchIdParam) => {
        const type = 'academicsUniversity';
        setLoading(true);

        defaultValueList.university = searchIdParam;
        setDefaultValueList(defaultValueList);
        defaultValues.academicsUniversity = searchIdParam;
        methods.setValue(type, searchIdParam);
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    const loadUniversityUnitById = async (searchIdParam) => {
        const type = 'academicUnit';
        setLoading(true);

        defaultValueList.researchCenter = searchIdParam;
        setDefaultValueList(defaultValueList);
        defaultValues.academicUnit = searchIdParam;
        methods.setValue(type, searchIdParam);
        searchCriteria.current = defaultValues;
        await loadServerRows(page, []);
        setLoading(false);
    };

    function defaultValueExpertise(expertiseId) {
        if (!!expertiseId) {
            loadExpertiseById(expertiseId);
        } else {
            defaultValues.expertises = [];
        }
    }

    function defaultValueDiscipline(disciplineId) {
        if (!!disciplineId) {
            loadDisciplineById(disciplineId);
        } else {
            defaultValues.disciplines = [];
        }
    }

    function defaultValueCountrieResearch(valueId) {
        if (!!valueId) {
            loadCountriesResearchById(valueId);
        } else {
            defaultValues.countriesResearch = [];
        }
    }

    function defaultValueWorkingLanguages(valueId) {
        if (!!valueId) {
            loadWorkingLanguageById(valueId);
        } else {
            defaultValues.workingLanguage = [];
        }
    }

    function defaultValueUniversity(valueId) {
        if (!!valueId) {
            loadUniversityById(valueId);
        } else {
            defaultValues.academicsUniversity = "";
        }
    }

    function defaultValueUniversityUnit(valueId) {
        if (!!valueId) {
            loadUniversityUnitById(valueId);
        } else {
            defaultValues.academicUnit = null;
        }
    }

    function defaultValue(searchIdParam, typeOfSearchParam) {
        let expertise = null;
        let discipline = null;
        let countrieResearch = null;
        let workingLanguages = null;
        let university = null;
        let universityUnit = null;

        if (!!typeOfSearchParam) {
            switch (typeOfSearchParam) {
                case 'expertise':
                    expertise = searchIdParam;
                    break;
                case 'discipline':
                    discipline = searchIdParam;
                    break;
                case 'countrieResearch':
                    countrieResearch = searchIdParam;
                    break;
                case 'workingLanguages':
                    workingLanguages = searchIdParam;
                    break;
                case 'university':
                    university = searchIdParam;
                    break;
                case 'universityUnit':
                    universityUnit = searchIdParam;
                    break;
            }
        }

        defaultValueExpertise(expertise);
        defaultValueDiscipline(discipline);
        defaultValueCountrieResearch(countrieResearch);
        defaultValueWorkingLanguages(workingLanguages);
        defaultValueUniversity(university);
        defaultValueUniversityUnit(universityUnit);
    }

    const loadAcademicsRows = async (page, sortModel, searchLetterParam) => {
        setLoading(true);
        const data = await academicService.searchOnlyAcademicsFOByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel,
            searchLetterParam,
            user?.userId
        );
        let content = data.data;
        setRows(content.content);
        setTotalRows(content.totalSize)
        setLoading(false);
    };

    function usePagination(data, itemsPerPage, totalRows) {
        const [currentPage, setCurrentPage] = useState(1);
        const maxPage = Math.ceil(totalRows / itemsPerPage);

        function currentData() {
            const begin = (currentPage - 1) * itemsPerPage;
            const end = begin + itemsPerPage;
            return data;
        }

        function next() {
            setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
        }

        function prev() {
            setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
        }

        function jump(page) {
            const pageNumber = Math.max(1, page);
            setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
        }

        return { next, prev, jump, currentData, currentPage, maxPage };
    }

    function pagination() {
        const rowByPage = pageSize;
        const pagePagination = page;
        const count = Math.ceil(totalRows / rowByPage);

        const countNbAcademics = () => {
            return totalRows;
        }

        const paginationCalculStartRange = () => {
            var startRange = (rowsPagination.currentPage - 1) * rowByPage
            return (startRange >= 0) ? startRange : 0
        };

        const paginationStartRangeShow = () => {
            return paginationCalculStartRange() + 1
        };

        const paginationEndRangeShow = () => {
            let endRange = paginationCalculStartRange() + rowByPage
            if (endRange > countNbAcademics()) {
                endRange = countNbAcademics()
            }
            return endRange
        };

        const rangeItems = () => {
            return `${paginationStartRangeShow()}-${paginationEndRangeShow()}`
        };

        const handleChange = (e, p) => {
            if (p != rowsPagination.currentPage) {
                setPage(p);
                rowsPagination.jump(p);
                loadAcademicsRows(p, [], searchLetter);
            }
        };

        return { count, rowByPage, pagePagination, countNbAcademics, rangeItems, handleChange };
    }

    function resetPagination() {
        setPage(1);
        rowsPagination.jump(1);
    }

    let rowsPagination = usePagination(rows, pageSize, totalRows);

    const getSearchParamsFromUrl = () => {
        const mapUrlParamToArray = (urlParamName) => {
            if(urlParams[urlParamName]){
                urlParams[urlParamName] = urlParams[urlParamName].split(',').map(value => { return { value: value }});
            } 
        }
        const urlParams = Object.fromEntries(new URLSearchParams(location?.search));
        const urlParamsToMap = ['disciplines', 'countriesResearch', 'workingLanguage', 'expertises'];
        urlParamsToMap.forEach(param => mapUrlParamToArray(param));
        return urlParams;        
    }

    useEffect(() => {
        const searchUrlParams = getSearchParamsFromUrl();   
        searchCriteria.current=searchUrlParams;
        methods.reset(searchUrlParams);
    }, []);

    useEffect(() => {
        if (!!searchId) {
            methods.reset();
            try {
                getUser().then(user => setUser(user?.data?.cvcUser));
                defaultValue(searchId, typeOfSearch);
            } catch (error) {
                console.error("error during first loading ", error);
            }
        } else {
            try {
                getUser().then(user => setUser(user?.data?.cvcUser));
                loadServerRows(page, []);
            } catch (error) {
                console.error("error during first loading ", error);
            }
        }
    }, [searchId]);

    useEffect(() => {
        let page = (rowsPagination.currentPage && rowsPagination.currentPage > 0) ? rowsPagination.currentPage : 1;
        setPage(page);
        rowsPagination.jump(page);
    }, [rows])

    return (
        <Layout>
            <Seo title={intl.formatMessage({ id: "academics.seo.title" })} lang={intl.locale} />            
            <WelcomeAcademics />
            <div id="main" className={searchStyles.main}>                
                <div id="searchEngine" className={searchStyles.searchEngine}>
                    <AcademicsListSearchEngine 
                        resetForm={resetForm} 
                        submitForm={submitForm} 
                        onClickSearchLetter={onClickSearchLetter}
                        methods={methods}
                        academicsLetter={academicsLetter}  
                        defaultValueList={defaultValueList} 
                        searchLetter={searchLetter} 
                    />
                </div>   
                <div id="pagination" className={searchStyles.pagination}>
                    {pagination().countNbAcademics() > 0 ? (
                        <AcademicsPagination pagination={pagination} title="paginationHaut" />
                            
                    ) : ""}
                </div>
                <div id="resultList" className={searchStyles.resultList}>
                    <AcademicsList academics={rowsPagination} pagination={pagination} loading={loading} />
                </div>                     
            </div>
        </Layout>
    );
};

export default AcademicsPage;

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LanguageIcon from '@mui/icons-material/Language';
import { Icon, Link, Stack } from "@mui/material";
import React, { Fragment } from "react";

const CvcLogoOrcid = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/ORCIDiD_icon24x24.png' />
            </Icon>
        </Fragment>)
};

const CvcLogoTwitter = () => {
    return (
        <Fragment>
        <Icon>
            <img src='/logo/information/icon_twitter.png' width={'24px'} height={'24px'} />
        </Icon>
    </Fragment>)
};

const CvcLogoLinkedIn = () => {
    return (
        <Fragment>
        <Icon>
            <img src='/logo/information/icon_linkedin.png' width={'24px'} height={'24px'} />
        </Icon>
    </Fragment>)
};

const CvcLogoWebPage = () => {
    return (
        <Fragment>
            <LanguageIcon color="primary" />
        </Fragment>)
};

const CvcLogoBiblio = () => {
    return (
        <Fragment>
            <AutoStoriesIcon color="primary" />
        </Fragment>)
};

const CvcLogoGoogleScholar = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/32px-Google_Scholar_logo.svg.png' width={'24px'} height={'24px'} />
            </Icon>
        </Fragment>)
};

const CvcLogoResearchGate = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/32px-ResearchGate_icon_SVG.svg.png' width={'24px'} height={'24px'} />
            </Icon>
        </Fragment>)
};


const CvcLogoWithLink = ({ code, link, logo, descr, id }) => {
    return (
        <Stack direction="row" spacing={1} pt={1}>
             {(logo)?logo():""} 
             <Link target={"_blank"} href={link} key={id} rel="noopener" variant="body1">{descr}</Link>
         </Stack>
    )
};


export {
    CvcLogoWithLink, CvcLogoOrcid, CvcLogoTwitter, CvcLogoWebPage, CvcLogoLinkedIn, CvcLogoBiblio, CvcLogoGoogleScholar, CvcLogoResearchGate
};


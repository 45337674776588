import { Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { NoResult } from "components/academics/common/SearchEngineComponents";
import themeMuiV5 from "components/common/ThemeMuiV5";
import { injectIntl } from "gatsby-plugin-intl";
import React, { Fragment } from "react";
import { AcademicsPagination } from "./AcademicsListComponents";
import AcademicsListDetail from "./AcademicsListDetail";


const AcademicsList = ({ intl, academics, pagination, loading }) => {

  return (
    <Fragment>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Card sx={{
          backgroundColor: themeMuiV5.palette.background.paper,
          border: "none",
          boxShadow: "none"
        }}>
          <CardHeader
            title={intl.formatMessage({ id: "academics.list" })}
            sx={{
              textAlign: "center",
              backgroundColor: themeMuiV5.palette.primary.main,
              color: themeMuiV5.palette.primary.contrastText,
              padding:0
            }}
          />
          <CardContent
            sx={{
              backgroundColor: themeMuiV5.palette.background.paper,
              padding:0,
            }}>
            {loading ?
              <CircularProgress />:
              (
                pagination().countNbAcademics() > 0 ?
                  (academics?.currentData()?.sort().map((academic, i) =>
                    (<AcademicsListDetail key={'academicDetail-' + i} academic={academic} />))) :
                  (<NoResult intl={intl} />)
              )
          }
          </CardContent>
          {pagination().countNbAcademics() > 0 ? (
              <Typography component='div' sx={{padding:1}}>
                <AcademicsPagination pagination={pagination} title="paginationBas"/>
              </Typography>
          ) : ""}
        </Card>
      </Grid>
    </Fragment>
  );
}

export default injectIntl(AcademicsList);
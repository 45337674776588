import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";

import {  CircularProgress, List, ListItem, ListItemButton, ListItemText, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import userService from "services/api/userService";


const getDocNameLabel = (document) => { 
    return document.documentName ? document.documentName : "No fileName"; 
}

const UserDocumentList = ({ userId }) => {
    const intl = useIntl();
    const theme = useTheme();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    const CustomizedList = styled(List)`
        padding: 0;
    `;

    const CustomizedListItem = styled(ListItem)`
        padding: 0;
    `;
    const CustomizedListItemButton = styled(ListItemButton)`
        padding: 0;
    `;
    const CustomizedListItemText = styled(ListItemText)`
        color: #244f9d;
    `;

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const fetchUserDocuments = async (userId) => {
            try {
                const results = await userService.findFOUserDocumentsByUserId(userId);
                const user = results.data;
                if (mounted) {
                    setDocuments(user?.documents);
                }
            } catch (error) {
                console.error("error fetching user's documents", error);
            } finally {
                setLoading(false);
            }
        }
        if (!!userId) {
            fetchUserDocuments(userId);
        }
        return () => { mounted = false };
    }, []);


    const createLinkAndDownloadPdf = (documentData, documentName) =>{
        const base64URL = documentData;
        const binary = atob(base64URL.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < len; i += 1) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        const file = new File([view], documentName, { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        let alink = document.createElement("a");
        document.body.appendChild(alink);
        alink.style = 'display: none';
        alink.href = url;

        alink.download = `${documentName}`;
        alink.click();
        window.URL.revokeObjectURL(url);
        alink.remove();
    }

    const handleViewPdf = (doc) => {
        userService
            .findFOUserDocumentsByDocumentId(doc.userOwnerId, doc.documentId)
            .then((response) => {
                createLinkAndDownloadPdf(response.data.documentData, doc.documentName);
            })
            .catch((error) => {
                handleApiCallError(error, "backoffice.users.edit.documents.view.error");
            });
    }

    const handleApiCallError = (error, errLabel) => {
        if (error && error.data && error.data.errorCode) {
            errLabel = error.data.errorCode;
        }
        console.error(intl.formatMessage({id: errLabel}), error);
    }
    
    return (
        <>
            {loading ?
                <CircularProgress />
                :
                documents?.length > 0 ?
                    <CustomizedList dense={true}>
                        {
                            documents.map((document, i) => {
                                return (
                                    <CustomizedListItem key={document.documentId}>
                                        <CustomizedListItemButton
                                            key={document.documentId}
                                            onClick={e => handleViewPdf(document)}
                                        >
                                            <CustomizedListItemText
                                                primary={`${getDocNameLabel(document)}`}
                                            />
                                        </CustomizedListItemButton>
                                    </CustomizedListItem>
                                );
                            })
                        }
                    </CustomizedList>
                    :
                    <Typography>{intl.formatMessage({ id: "myDocuments.noDocument" })}</Typography>
            }
        </>
    )

}

export default UserDocumentList;
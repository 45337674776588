import React, {useCallback, useEffect, useState} from 'react';

import {Box, Card, CardContent, CircularProgress, Grid, Typography} from '@mui/material';
import {useIntl} from 'gatsby-plugin-intl';
import { navigate } from 'gatsby-plugin-intl';

import {getUser} from 'services/api/requests';
import WelcomeMessage from 'components/common/WelcomeMessage';
import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import moment from "moment";
import CourseCardList from './catalog/CourseCardList';
import UpcomingSessionList from './catalog/UpcomingSessionList';
import { isPastBySeason } from 'utils/date-utils';



const MyCoursesPage = () => {

    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const initialUser = {
        cvcUser: {
            courses: [],
            sessions: [],
        },
        samlUserDetails: {}
    }
    let [user, setUser] = useState(initialUser);
    let [upcomingSessions, setUpcomingSessions] = useState([]);
    let [futureCourses, setFutureCourses] = useState([]);
    let [pastCourses, setPastCourses] = useState([]);


    const findFutureSessions = async (sessions) => {
        return sessions.filter(
            session =>
                moment(new Date(session.endTime)).isAfter(new Date()) || (session.endTime == undefined || session.endTime == null)
        );
    }

    const sortByNextSessionDate = (a,b) => {
        if (a.nextSessionDate < b.nextSessionDate)
            return -1;
        if (a.nextSessionDate > b.nextSessionDate)
            return 1;        
        if (a.semester < b.semester)
            return -1;
        if (a.semester > b.semester)
            return 1;        
        return 0;
    }

    const sortByLastSessionDate = (a,b) => {
        if (a.lastSessionDate < b.lastSessionDate)
            return 1;
        if (a.lastSessionDate > b.lastSessionDate)
            return -1;
        if (a.semester < b.semester)
            return 1;
        if (a.semester > b.semester)
            return -1;                
        return 0;
    }

    const findFutureAndPastCourses = async (courses, futureSessions) => {
        let futureCourseIds = [];
        for(var i=0; i < futureSessions.length; i++){
            futureCourseIds.push(futureSessions[i].courseId);
        }
        let futureCourses = [];
        let pastCourses = [];          
        courses.forEach(
            course => { 
                if(course.courseId != null){
                    if(futureCourseIds.includes(course.courseId)){
                        futureCourses.push(course);
                    } else {
                        if(moment(new Date(course.lastSessionDate)).isBefore(new Date()) || isPastBySeason(course.semester)){
                            pastCourses.push(course);
                        }else {
                            futureCourses.push(course);
                        }                        
                    }
                }
            }
        );
        futureCourses.sort(sortByNextSessionDate);
        pastCourses.sort(sortByLastSessionDate);        
        return {
            pastCourses: pastCourses,
            futureCourses: futureCourses
        };
    }
    
    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoading(true);
            try {
                let user = await getUser();                
                if(mounted) {
                    setUser(user.data);
                    const futureSessions = await findFutureSessions(user?.data?.cvcUser?.sessions);
                    setUpcomingSessions(futureSessions);
                    const pastAndFutureCourses = await findFutureAndPastCourses(user?.data?.cvcUser?.courses, futureSessions);
                    setFutureCourses(pastAndFutureCourses?.futureCourses);
                    setPastCourses(pastAndFutureCourses?.pastCourses);
                }
            } catch (error) {
                console.error("error fetching user datas & the future sessions and future/past courses of user ", error);
            } finally{
                setLoading(false);
            }        
        };
        fetchData();        
        return () => {
            mounted = false;
        }
    }, []);


    const handleSessionClick = (courseId, sessionId) => {
        navigate("/courses/" + courseId + "/session/" + sessionId);
    }

    return (
        <Layout>
            <Seo
                title={intl.formatMessage({id: "myCourses.seo.title"})}
                lang={intl.locale}
            />
            <Box>
            {
                loading ? 
                <Box style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                :    
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <WelcomeMessage user={user}/>
                    </Grid>
                    {
                        upcomingSessions?.length > 0 && 
                        <Grid item xs={12} lg={3.5}>
                            <UpcomingSessionList 
                                sessions={upcomingSessions}
                                courses={user.cvcUser?.courses}
                                loading={loading}
                                onSessionClick={handleSessionClick}
                                height={'auto'} 
                            />  
                        </Grid>
                    }
                    {                        
                        <Grid item xs={12} lg={8.5}>
                            <CourseCardList courses={futureCourses} isPast={false} />
                            { pastCourses?.length > 0 ? <CourseCardList courses={pastCourses} isPast={true} /> : <></> }
                        </Grid>                        
                    }
                </Grid>
            }
            </Box>
        </Layout>

    )

}

export default MyCoursesPage;

import { Avatar, Card, Chip, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import { navigate } from 'gatsby-link';
import { injectIntl } from "gatsby-plugin-intl";
import React, { useEffect, useState } from "react";
import { universities } from 'utils/constants/constants';


const AcademicDetailList = ({ intl, academic }) => {

    let [image, setImage] = useState("");
    let [imageAlt, setImageAlt] = useState({});

    let [photo, setPhoto] = useState("");

    useEffect(() => {
        let univ = universities.find(univ => univ.acronym === academic?.universityAcronym);
        if (typeof univ !== "undefined" && univ !== null) {
            setImage(univ.img);
            setImageAlt(univ.name);
        } else {
            setImage(null);
        }

        if (typeof academic?.photo?.stream == "undefined" || academic?.photo?.stream == null || academic?.photo?.stream?.length == 0) {
            setPhoto('/photo/academicDefaultProfil.png');
        } else {
            setPhoto(`data:image/jpeg;base64,` + academic.photo.stream);
        }
    }, [academic]);

    function truncate(str, n) {
        return (str?.length > n) ? str.substr(0, n - 1) + '...' : str;
    };

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    });

    function navigateClickSearch(e, search, value) {
        e.stopPropagation()
        navigate(`/${intl.locale}/faculty/search${search}/` + value)
    }

    return (
        <Card
            sx={{
                marginTop: themeMuiV5.spacing(2),
                padding: themeMuiV5.spacing(1),
                cursor: "pointer"
            }}

            onClick={() => navigate("/" + intl.locale + "/faculty/" + academic?.academicId)}
        >
            <Grid container>
                <Grid item xs="auto" pr={1}>
                    <Typography component="div">
                        <Avatar alt={"photo " + academic?.lastName} src={photo} sx={{ width: 100, height: 100 }} />
                    </Typography>
                </Grid>
                <Grid item xs >
                    <Grid item container xs={12} justifyContent='center'>
                        <Grid item xs={8}>
                            <Typography variant="h6" component="h2">
                                {academic.lastName} {academic.firstName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Grid item container xs={12}
                                direction="row"
                                justifyContent="flex-end"
                            >
                                {(image ?
                                    <Typography component="div">
                                        <IconButton
                                            aria-label={intl.formatMessage({ id: "academics.detail.university" })}
                                            color="primary"
                                            onClick={(e) => navigateClickSearch(e, 'University', academic?.universityId)}
                                            sx={{ paddingBottom: 0, paddingTop: 0 }}
                                        >
                                            <Img src={image}
                                                alt={"logo " + imageAlt}
                                                sx={{ maxHeight: '35px'
                                                ,maxWidth:'100px'
                                                }}
                                            />
                                        </IconButton>
                                    </Typography> : ''
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} >
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                component="span"
                                align="justify">
                                {academic.titles && academic.titles?.length > 0 ? academic.titles.sort().map((title, i) =>
                                (
                                    <p key={'titre-' + i} style={{ display: 'inline' }}>{title.label}{(i < academic.titles.length - 1) ? ', ' : ''}</p>
                                )
                                ) : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item container xs={12}
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <Link
                                    href='#'
                                    key={`researchCenter-${academic.academicId}`}
                                    variant="body2"
                                    align='right'
                                    underline='hover'
                                    onClick={(e) => navigateClickSearch(e, 'UniversityUnit', academic?.universityUnitId)}
                                    >
                                    {academic?.universityUnitName}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} >
                        <Typography
                            variant="subtitle2"
                            component="span"
                            align="justify">
                            <Typography component="div" pt={0.5}>
                                {academic.fieldsOfExpertise && academic.fieldsOfExpertise?.length > 0 ? academic.fieldsOfExpertise.sort().map((expertise, i) =>
                                (
                                    <Chip
                                        key={'expertise-' + i}
                                        label={expertise.label}
                                        onClick={(e) => navigateClickSearch(e, 'Expertise', expertise?.value)}
                                    />
                                )
                                ) : ''}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} >
                        <Typography variant="body2" align='justify' pt={1}>
                            {truncate(academic.description, 200)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default injectIntl(AcademicDetailList);
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';

import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';

import { PrivateRoutePath } from 'router/PrivateRoute';
import { isLoggedIn, logout } from 'services/auth';
import themeMuiV5 from 'components/common/ThemeMuiV5';

import LoginPage from 'components/login/LoginPage';
import HomePage from 'components/HomePage';
import CourseListPage from 'components/catalog/courses/CourseListPage';
import CourseDetailPage from 'components/catalog/course/CourseDetailPage'
import MyCoursesPage from 'components/MyCoursesPage';
import NewsPage from 'components/news/NewsPage';
import AcademicDetailPage from 'components/academics/academic/AcademicDetailPage';
import AcademicsPage from 'components/academics/academics/AcademicsPage';



const IndexPage = ( ) => {

  React.useEffect(()=>{
    if(!isLoggedIn()){
      logout();
    } 
  },[])
   
    return (

        <ThemeProviderV5 theme={themeMuiV5}>
        <Router basepath="/:lang">
              <PrivateRoutePath path="/opportunity/:newsId" component={NewsPage}/>
              <PrivateRoutePath path="/my-courses/" component={MyCoursesPage}/>
              <PrivateRoutePath path="/catalog" component={CourseListPage}/>
              <PrivateRoutePath path="/catalog/:courseType" component={CourseListPage}/>
              <PrivateRoutePath path="/courses/:courseId" component={CourseDetailPage}/>
              <PrivateRoutePath path="/courses/:courseId/session/:sessionId" component={CourseDetailPage}/>
              <PrivateRoutePath path="/education/bachelor/engage-track"/>
              <PrivateRoutePath path="/faculty/" component={AcademicsPage} customProps={'test1'}/>
              <PrivateRoutePath path="/faculty/:academicId" component={AcademicDetailPage}/>
              <PrivateRoutePath path="/faculty/searchExpertise/:searchId" component={AcademicsPage} typeOfSearch={'expertise'}/>
              <PrivateRoutePath path="/faculty/searchDiscipline/:searchId" component={AcademicsPage} typeOfSearch={'discipline'}/>
              <PrivateRoutePath path="/faculty/searchCountrieResearch/:searchId" component={AcademicsPage} typeOfSearch={'countrieResearch'}/>
              <PrivateRoutePath path="/faculty/searchWorkingLanguages/:searchId" component={AcademicsPage} typeOfSearch={'workingLanguages'}/>
              <PrivateRoutePath path="/faculty/searchUniversity/:searchId" component={AcademicsPage} typeOfSearch={'university'}/>
              <PrivateRoutePath path="/faculty/searchUniversityUnit/:searchId" component={AcademicsPage} typeOfSearch={'universityUnit'}/>
              <PrivateRoutePath path="/" component={HomePage}/>
              <PrivateRoutePath path="/:paramPage" component={HomePage}/>
              <LoginPage path={`/login`}/>
          </Router>
          </ThemeProviderV5>

    )
   
    }
  
  export default IndexPage


import React from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';

import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const CvcBackButton = () => {
    const intl = useIntl();
    const goBack = () => {
        navigate(-1);
    };
    return (    
        <Button variant='contained' color='primary' sx={{ marginBottom: '15px', width: '110px' }}
            onClick={ goBack } >
            <ArrowBackIosNewIcon sx={{ verticalAlign: 'middle' }} /> { intl.formatMessage({id: "common.button.goBack"}) }
        </Button>
    );
};

export default CvcBackButton;

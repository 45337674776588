import React, {Fragment} from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';

import {useIntl} from 'gatsby-plugin-intl';
import {CATALOG_FIELDS_BY_TYPE, COURSE_TYPE} from 'utils/constants/courses-constants';

import themeMuiV5 from 'components/common/ThemeMuiV5';
import CourseCard from 'components/catalog/courses/CourseCard';


const NoResultMessage = ( {loading} ) => {
    const intl = useIntl();
    return (!loading && 
        <Typography align='center'>
            {intl.formatMessage({id: "common.label.no.result"})}
        </Typography>
    );
}

const ScrollingResultMessageUp = ( {coursesLenght, coursesTotalSize} ) => {
    const intl = useIntl();
    return (<> 
            <Typography align='center'>             
                {coursesLenght} {intl.formatMessage({id: "course.pagination.scroll.result"})} {coursesTotalSize}
            </Typography>
            { coursesLenght < coursesTotalSize &&
                <Typography align='center'>             
                    {intl.formatMessage({id: "course.pagination.scroll.helper.text"})}
                </Typography>
            }            
        </>);
}


const ScrollingResultMessageDown = ( {coursesLenght, coursesTotalSize} ) => {
    const intl = useIntl();
    return ( 
        <Typography align='center'>             
            {coursesLenght} {intl.formatMessage({id: "course.pagination.scroll.result"})} {coursesTotalSize}
        </Typography>
    );
}

const CourseList = ({ courseType, courses, coursesTotalSize, loading, loaderRef}) => {

    const intl = useIntl();
    const courseListTitle = courseType ?  intl.formatMessage({id: "course.list."+courseType.toString().toLowerCase()}) : intl.formatMessage({id: "course.list"});
    const isAdditionnalCourse = (courseType == COURSE_TYPE.additional) ? true : false;
    const fieldsByType = CATALOG_FIELDS_BY_TYPE(courseType);
    
    return (<>
        <Grid item xs={12} md={8}>

            <Card
                sx={{backgroundColor: themeMuiV5.palette.background.paper,}}
            >
                <CardHeader
                    title={courseListTitle}
                    sx={{
                        textAlign: 'center',
                        backgroundColor: themeMuiV5.palette.primary.main,
                        color: themeMuiV5.palette.primary.contrastText
                    }}
                />
                <CardContent
                    sx={{backgroundColor: themeMuiV5.palette.background.paper,}}
                >
                    
                    <div className="CourseListContainer">
                        { courses && courses.length > 0 ? (<>
                                <ScrollingResultMessageUp 
                                    coursesLenght={courses.length} 
                                    coursesTotalSize={coursesTotalSize} 
                                />
                                { courses.map((course, index) => {
                                        return (
                                            <CourseCard 
                                                key={index} 
                                                course={course} 
                                                isAdditionnalCourse={isAdditionnalCourse}
                                                fieldsByType={fieldsByType}
                                            />
                                        )
                                    })
                                }
                                <ScrollingResultMessageDown 
                                    coursesLenght={courses.length} 
                                    coursesTotalSize={coursesTotalSize} 
                                />                                 
                            </>)
                            :
                            <NoResultMessage loading={loading} />
                        }
                        
                        <div ref={loaderRef}>
                            { loading &&                                 
                                <div style={{ display: 'flex', 
                                        justifyContent: 'center', 
                                        alignContent: 'center',
                                        marginTop: '20px'}} >                                    
                                    <CircularProgress size={40} disableShrink={true}/>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <h1>Loading ...</h1>                                    
                                </div>                                                                
                            }
                        </div>
                    </div>                    
                </CardContent>
            </Card>
        </Grid>
    </>);
}

export default CourseList;

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import {useForm} from 'react-hook-form';
import {useIntl} from 'gatsby-plugin-intl';

import { CATALOG_CRITERIA_BY_TYPE } from 'utils/constants/courses-constants';
import {getUser} from 'services/api/requests';
import courseService from 'services/api/courseService';
import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import WelcomeMessage from 'components/common/WelcomeMessage';
import SearchEngine from 'components/catalog/courses/SearchEngine';
import CourseList from 'components/catalog/courses/CourseList.js';
import * as searchStyles from 'styles/search-page.module.scss';
import ResultSnackbars from 'components/backoffice/common/ResultSnackbars';

const CourseListPage = ({ courseType }) => {

    const intl = useIntl();
    let sortModelCourse = [
        {
            field: 'semester',
            sort: 'desc',
        },
        {
            field: 'first_session_date',
            sort: 'asc',
        }
    ];
    let sortModelAdditional = [
        {
            field: 'course_id',
            sort: 'desc',
        },
        {
            field: 'title',
            sort: 'asc',
        }
    ];
   
    const PAGE_SIZE = 20;
    const sortModel = courseType ? sortModelAdditional : sortModelCourse;
    const [page, setPage] = useState(1);    
    const [totalSize, setTotalSize] = useState(0);
    const [user, setUser] = useState({});
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [loaderVisible, setLoaderVisible] = useState(false);
    const loaderRef = useRef(null);
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    
    const defaultValues = useMemo(() => {
        return {
            textFilter: '',
            courseType: '',
            courseSubtype: '',
            courseUniversity: '',
            courseCycle: '',
            coursePeriod: '',
            courseLanguage: '',
            topics: [],
            engageCourse:false,
        }
    },[]);
    const methods = useForm({defaultValues: defaultValues, mode: "onChange"});
    const searchCriteria = useRef({});

    const goToSearchUrl = () => {
        const additionnalQueryString = courseType ? ('/'+courseType) : '';
        const searchQueryString = buildSearchQueryString();
        window.location.replace(`/${intl.locale}/catalog${additionnalQueryString}${searchQueryString}`);
    }

    const buildSearchQueryString = () => {
        let searchQueryString = "";
        const searchCriterias = searchCriteria.current;
        for (const [key, value] of Object.entries(searchCriterias)) {
            if(value == null || value == undefined || value == '' || value.length == 0){
                delete searchCriterias[key];
            }
        }
        const urlSearchParams = new URLSearchParams(searchCriterias);
        searchQueryString = urlSearchParams && urlSearchParams.size > 0 ? ('?'+urlSearchParams.toString()):'';        
        return searchQueryString;
    }

    const resetForm = () => {
        methods.reset(defaultValues);
        searchCriteria.current = methods.getValues();
        goToSearchUrl();        
    };

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        goToSearchUrl();
    };

    const getSearchParamsFromUrl = () => {
        const urlParams = Object.fromEntries(new URLSearchParams(location?.search));
        if(urlParams['engageCourse']){
            urlParams['engageCourse'] = urlParams['engageCourse'] === 'true';
        }
        if(urlParams['topics']){
            urlParams['topics'] = urlParams['topics'].split(',');
        }
        return urlParams;        
    }

    const isPermissionError = (errorCode) => {
        let errorPermission = false
        if (401 == errorCode || 403 == errorCode) {
            errorPermission = true;
        }
        return errorPermission;
    }
    const showErrorMessage = (errorMessage) => {
        setErrorMessage(errorMessage);
        setOpenError(true);
    }

    useEffect(() => {
        const initSearchCriterias = async () => {
            const urlParams = getSearchParamsFromUrl();   
            searchCriteria.current=urlParams;
            methods.reset(urlParams);
            if (courseType) {
                searchCriteria.current.courseType = courseType
            }            
        }
        const initUser = async () => {
            try {
                let user = await getUser();
                setUser(user.data)
            } catch (error) {
                console.error('error getting user', error);
            }
        };
        const initCourses = async (page) => {
            //console.log(`init courses... page: ${page}, pageSize: ${PAGE_SIZE}`);
            try {   
                const start = performance.now();     
                const result = await courseService.searchCatalogByCriteria(
                    searchCriteria.current,
                    page,
                    PAGE_SIZE,
                    sortModel
                );
                const end = performance.now();  
                const time = Math.round(end - start);   
                //console.log(`init courses in ${time == 0 ? '<50' : time} ms - success (${result?.data?.content?.length} of ${result?.data?.totalSize} courses)`, result?.data?.content);
                setCourses(result?.data?.content);
                setTotalSize(result?.data?.totalSize);        
            } catch (error) {
                console.error('error initializing courses', error);
                if (isPermissionError(error.status)) {
                    showErrorMessage("course.error.permission");
                }
            } 
        };
        const initPage = async () => {
            setLoading(true);            
            await initSearchCriterias();
            await initUser();
            await initCourses(0);
            setLoading(false);                 
        }
        
        initPage();
    }, []);


    useEffect(() => {
        const loadNewCourses = async () => {          
          //console.log(`load new courses ${PAGE_SIZE} on ${totalSize}... (courses already loaded : ${courses.length})`);
          setLoading(true);
          try {
            const start = performance.now();             
            const result = await courseService.searchCatalogByCriteria(
                searchCriteria.current,
                page,
                PAGE_SIZE,
                sortModel
            );
            const end = performance.now();  
            const time = Math.round(end - start); 
            //console.log(`load new courses in ${time == 0 ? '<50' : time} ms - success (${result?.data?.content?.length} of ${result?.data?.totalSize} courses)`, result?.data?.content);
            setPage((prevPage) => prevPage + 1);
            setCourses((prevCourses) => [...prevCourses, ...result.data.content]);                    
          } catch (error) {
            console.error('error loading new courses', error);
            if (isPermissionError(error.status)) {
                showErrorMessage("course.error.permission");
            }
          }
          setLoading(false); 
        }
        if(!loading && loaderVisible && courses.length < totalSize){
            loadNewCourses();
            setLoaderVisible(false);            
        }        
      }, [loaderVisible]);


    const callbackFunction = (entries) => {
        const [ entry ] = entries;
        setLoaderVisible(entry.isIntersecting);
    }
    
    const options = {
        root: null,
        rootMargin: "0px",
        threshold:1.0
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        if (loaderRef.current) observer.observe(loaderRef.current)
        
        return () => {
          if(loaderRef.current) observer.unobserve(loaderRef.current)
        }
      }, [loaderRef, options]);

    return (
        <Layout>
            <Seo
                title={intl.formatMessage({id: "course.catalog"})}
                lang={intl.locale}
            />

            <WelcomeMessage user={user}/>
            
            {courseType ?
                <Grid item xs={12} style={{margin: "10px"}} >
                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "course.opportunity.desc" })}
                    </Typography>
                </Grid>
            :   <Grid item xs={12} style={{margin: "10px"}}>
                    <Typography variant="subtitle1">
                        {intl.formatMessage({ id: "course.desc" })}
                    </Typography>
                </Grid>
            }

            <div id="main" className={searchStyles.main}>                
                <div id="searchEngine" className={searchStyles.searchEngine}>
                    <SearchEngine
                        resetForm={resetForm}
                        submitForm={submitForm}
                        methods={methods}
                        courseType={courseType}
                        filters={CATALOG_CRITERIA_BY_TYPE(courseType)}
                    />
                </div> 
                <ResultSnackbars
                    messageId={errorMessage}
                    setOpenError={setOpenError}
                    openError={openError}                    
                    setOpenSuccess={null}
                    openSuccess={false}                    
                />      
                <div id="resultList" className={searchStyles.resultList}>
                    <CourseList
                        courseType={courseType}
                        courses={courses}
                        coursesTotalSize={totalSize}
                        loading={loading}
                        loaderRef={loaderRef}                     
                    />
                </div>                     
            </div>
   
        </Layout>
    );
};

export default CourseListPage;
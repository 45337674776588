import React, { useEffect, useState} from 'react';

import { Card, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';

import moment from 'moment';
import { navigate, useIntl} from 'gatsby-plugin-intl';
import {getNews} from 'services/api/newsService';
import courseService from 'services/api/courseService';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import useScreenSize from "components/common/useScreenSize";

const NewsList = ({ user, height='100%' }) => {
    const intl = useIntl();
    const initialNews = [];
    let [news, setNews] = useState(initialNews);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const screenHeight = useScreenSize().height;
    const styles = {
        card: {
            width: '100%',
            height: isMobile ? 'auto' : screenHeight > 600 ? height: 'auto',
        }, 
        cardHeader: {
            textAlign: 'center',
            backgroundColor: themeMuiV5.palette.primary.main,
            color: themeMuiV5.palette.primary.contrastText
        },
        cardContent: {
            height: '100%',
            minHeight: '250px',
            overflowY: 'auto'
        }
    };

    useEffect(() => {
        setLoading(true);
        let mounted = true;
        getNews().then(
            (news) => {
                if(mounted) {
                    setNews(news.data);
                    setLoading(false);
                }
              }            
        ).catch((error)=> {
            console.error("error getting news ", error);
            setLoading(false);
        }
        );
        return () => {
            mounted = false;
        }
    }, []);

    return (
        <>
        {  user &&
            <Card sx={ styles.card }>
                <CardHeader
                    title={intl.formatMessage({ id: "lov.opportunity.type.news" })}
                    sx={ styles.cardHeader }
                />
                <CardContent sx={ styles.cardContent }>
                    {
                        loading ?
                            <CircularProgress /> 
                            :
                            news.map((item, i) => <Item key={i} item={item} />)
                    }
                </CardContent>
            </Card>
        }
        </>
    );

};

const Item = ({item}) => {

    const intl = useIntl();
    const handleClick = () => {
        if(item.contentMarkdown){
            navigate("/opportunity/" + item.newsId);
        }else{
            window.open(item.linkUrl, "_blank")
        }
    }

    return (
        <div onClick={handleClick}>
            <Card
                sx={{
                    marginBottom: 2
                }}
            >
                    {(item?.media?.stream || item?.coverImageUrl)  && 
                        <CardMedia
                            image={item.media.stream ? `data:image/jpeg;base64,${item.media.stream }` : item?.coverImageUrl}
                            component="img"
                            title={item.title}
                            alt={item.title}
                            sx={{
                                height: 140,
                            }}
                        />
                    }
                    <CardContent>

                        <Typography gutterBottom variant="body2" component="div"
                            sx={{
                                color: 'black',
                                marginBottom:0.5
                            }}
                        >
                            {item.deadline && (
                                <>
                                    {intl.formatMessage({id:"opportunity.deadline.date.texte"}) +  moment(item.deadline).format("DD/MM/YYYY")}
                                </>
                            )}
                            {item.keywords.map((keyword, i) =>
                                <Chip key={i} variant={"outlined"}
                                    size="small"
                                    label={intl.formatMessage({
                                        id: courseService.getLabelKeyByGroupAndCode(
                                            null,
                                            keyword
                                        ),
                                    })}
                                    sx={{
                                        float: 'right',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        border: "none",
                                        color: "black"
                                    }}
                                />
                            )}
                        </Typography>
                    
                        <Typography variant="body2" component="p"
                            color="textSecondary"
                            align="left"
                            sx={{
                                color: themeMuiV5.palette.primary.main,
                                float:'none'
                            }}
                        >
                            {item.title}
                        </Typography>
                    </CardContent>
            </Card>
        </div>
    )
}

export default NewsList;

import React, {Fragment} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';

import { GROUP } from 'utils/constants/back-office-constants';
import { CATALOG_CRITERIA } from 'utils/constants/courses-constants';
import { RhfListOfValuesSelect, RhfSwitch, RhfTextField } from 'components/backoffice/common/RhfFields';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import RhfLovCheckboxList from 'ui/RhfLovCheckboxList';
import { useIntl } from 'gatsby-plugin-intl';


const useStyles = makeStyles((theme) => ({
    denseCheckbox:{
        padding:0,
        minWidth:0
    },
    transfer: {
        margin: 'auto',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20,
    },
    details: {
        alignItems: "center",
    },
    column: {
        flexBasis: "33.33%",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    textField: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        width: "100%",
    },
    topics: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        maxHeight: 200,
        overflowY:"auto"
    },
    formControlLabel: {
        verticalAlign: "bottom",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(8),
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        "&:hover": {
            borderWidth: "0 0 3px",
            borderStyle: "solid",
            borderColor: "#20a842",
        },
    },
}));

const SearchEngine = ({ resetForm, submitForm, methods, filters, courseType}) => {

    const intl = useIntl();
    const classes = useStyles();

    const handleSubmitForm = (formData) => {
        submitForm(formData);
    };

    return (
        <Fragment>
            <Grid item lg={4} md={4} sm={12} xs={12} 
            sx={{
                width: "100%",
            }}>
                <form onSubmit={methods.handleSubmit(handleSubmitForm)} noValidate>

                    <Card 
                        sx={{
                            maxHeight: '100%',
                            backgroundColor: themeMuiV5.palette.background.paper,
                            }}
                    >

                        <CardHeader
                            title={intl.formatMessage({ id: "course.search.engine" })}
                            sx={{
                                textAlign: "center",
                                backgroundColor: themeMuiV5.palette.primary.main,
                                color: themeMuiV5.palette.primary.contrastText
                            }}
                        >

                        </CardHeader>
                        <CardContent 
                            sx={{
                                backgroundColor: themeMuiV5.palette.background.paper,
                            }}
                            >
                            <Grid container spacing={1}
                                sx={{
                                    width: "100%",
                                }}
                            >

                                {filters.includes(CATALOG_CRITERIA.fullText) && <RhfTextField
                                    name="textFilter"
                                    helpMessage={intl.formatMessage({
                                        id: "course.filter.text.filter.help.text",
                                    })}
                                    label={"course.search.free.text"}
                                    control={methods.control}
                                    id="filter-search-input-text"
                                    fullWidth
                                />}
                                {filters.includes(CATALOG_CRITERIA.courseType) && <RhfListOfValuesSelect
                                    name="courseType"
                                    label={"course.type"}
                                    group={GROUP.courseType}
                                    control={methods.control}
                                    className={classes.textField}
                                    id="filter-type-select"
                                />}
                                {filters.includes(CATALOG_CRITERIA.courseSubtype)  && (
                                    <RhfListOfValuesSelect
                                        name="courseSubtype"
                                        label={"course.subtype"}
                                        group={GROUP.courseSubtype}
                                        control={methods.control}
                                        className={classes.textField}
                                        id="filter-subtype-select"
                                    />
                                )}
                                {filters.includes(CATALOG_CRITERIA.universityCode) && <RhfListOfValuesSelect
                                    id="filter-main-university-select"
                                    name="courseUniversity"
                                    label={"course.university"}
                                    group={GROUP.universityAll}
                                    control={methods.control}
                                    className={classes.textField}
                                />}

                                {filters.includes(CATALOG_CRITERIA.courseCycle) && <RhfListOfValuesSelect
                                    name="courseCycle"
                                    label={"course.cycle"}
                                    group={GROUP.courseCycle}
                                    control={methods.control}
                                    className={classes.textField}
                                    id="filter-cycle-select"
                                />}
                                {filters.includes(CATALOG_CRITERIA.period) &&  <RhfListOfValuesSelect
                                    name="coursePeriod"
                                    label="course.period"
                                    group={GROUP.period}
                                    control={methods.control}
                                    className={classes.textField}
                                    id="course-subtype-select"
                                />
                                }
                                {filters.includes(CATALOG_CRITERIA.teacher) &&
                                    <RhfListOfValuesSelect
                                        name="courseTeacher"
                                        label={"course.teachers"}
                                        group={GROUP.teacherVisible}
                                        control={methods.control}
                                        className={classes.textField}
                                        id="filter-teacher-select"
                                    />}
                                {filters.includes(CATALOG_CRITERIA.language) &&
                                    <RhfListOfValuesSelect
                                        name="courseLanguage"
                                        label="course.course.language"
                                        group={GROUP.language}
                                        control={methods.control}
                                        className={classes.textField}
                                        id="course-language-select"
                                    />
                                }
                                {filters.includes(CATALOG_CRITERIA.engageCourse) &&
                                    <Typography component={"div"} mt={themeMuiV5.spacing(1)}>
                                        <RhfSwitch
                                            label="course.engage.course"
                                            name="engageCourse"
                                            className={classes.formControlLabel}
                                            control={methods.control}
                                        />
                                    </Typography>
                                }
                                {filters.includes(CATALOG_CRITERIA.courseAttend) &&
                                    <RhfSwitch
                                        label="course.open.to.registration"
                                        name="openToRegistration"
                                        className={classes.formControlLabel}
                                        control={methods.control}
                                    />
                                }
                                {filters.includes(CATALOG_CRITERIA.topic) &&
                                <RhfLovCheckboxList
                                    name="topics"
                                    label="course.topic"
                                    listType={GROUP.topic}
                                    className={classes.topics}
                                    getValues={methods.getValues}
                                    control={methods.control}
                                />}
                            </Grid>
                        </CardContent>
                        <Divider  style={
                            {
                                marginTop: '10px'
                            }
                        }/>

                        <CardActions
                            // style={
                            //     {
                            //         justifyContent: 'center'
                            //     }
                            // }
                            sx={{ justifyContent: 'center' }}
                        >
                            <Button  color="secondary" onClick={resetForm}>
                                {intl.formatMessage({id: "course.filter.reset"})}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                {intl.formatMessage({id: "course.filter.search"})}
                            </Button>
                        </CardActions>

                    </Card>
                </form>
            </Grid>
        </Fragment>
    )
};
export default SearchEngine;

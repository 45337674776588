import React, { useEffect } from "react";
import {useIntl} from "gatsby-plugin-intl";
import { Controller } from "react-hook-form";

import { FormControl, FormControlLabel, FormHelperText, FormLabel, Checkbox, FormGroup } from "@mui/material";

import courseService from "services/api/courseService";
import { getListOfValuesByGroup } from "services/api/requests";


const sortByLabel = (item1, item2) => {
    if (item1.label < item2.label) {
        return -1;
      }
      if (item1.label > item2.label) {
        return 1;
      }
      return 0;
}

const RhfLovCheckboxList = ({
    name,
    label,
    listType,
    className,
    getValues,
    control,
    disabled = false,
    error = null,
}) => {
    const intl = useIntl();
    let [valuesList, setValuesList] = React.useState([]);

    const lovList2itemsList = (lovArr, itemArr) => {
        for (let i = 0; i < lovArr.length; i++) {
            const lovObject = lovArr[i];
            const intlId = courseService.getLabelKeyByGroupAndCode(
                lovObject.typeCode, 
                lovObject.label
            );
            const label = intl.formatMessage({ id: intlId });
            const item = { key: lovObject.value, label: label };
            itemArr.push(item);
        }
        itemArr.sort(sortByLabel);
    }

    const fetchData = async () => {
        try {
            const lovList = await getListOfValuesByGroup(listType);
            const items = [];
            lovList2itemsList(lovList, items);
            setValuesList(items);
        } catch (error) {
            console.error(`error fetching list of values ${listType} `, error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [setValuesList, listType]);

    const handleCheck = (checkedId) => {
        const ids = getValues(name);
        const newIds = ids.includes(checkedId) ? ids.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
        return newIds;
    };

    return (
        <FormControl error={!!error} component="fieldset" className={className}>
            
            <FormLabel component="legend">{intl.formatMessage({id: label})}</FormLabel>
            <FormGroup>
                <Controller
                    name={name}
                    control={control}
                    render={
                        ({field}) => 
                            // @ts-ignore
                            valuesList &&
                            valuesList.length > 0 &&
                            valuesList.map((item, index) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            color="primary"
                                            onChange={() => {
                                                field.onChange(handleCheck(item.key));
                                            }}
                                            checked={field.value.includes(item.key)}
                                        />
                                    }
                                    key={item.key}
                                    label={item.label}
                                />
                            ))
                    }
                />
            </FormGroup>
            <FormHelperText>{error ? intl.formatMessage({id: error.message}) : null}</FormHelperText>
        </FormControl>
    );

};

export default RhfLovCheckboxList;

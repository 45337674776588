import React from 'react';

import { Card, CardHeader, CardContent, List, ListItem, ListItemText, IconButton, Typography, CircularProgress, Paper, Grid, Tooltip } from '@mui/material';

import themeMuiV5 from 'components/common/ThemeMuiV5';
import { useIntl } from 'gatsby-plugin-intl';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import { dateTime2LocalHour, dateTime2LocalShortDay } from 'utils/date-utils';

const UpcomingSessionList = ({ sessions, courses, loading, onSessionClick, height='100%', width='100%' }) => {

    const intl = useIntl();
    const styles = { 
        card: {
            height: height,
            width: width, //'30ch'
            overflowY: 'auto'
        },
        cardHeader: {
            textAlign: 'center',
            backgroundColor: themeMuiV5.palette.primary.main,
            color: themeMuiV5.palette.primary.contrastText
        },
        cardContent: {
            padding: '0px 0px 0px !important'
        }
    };
    return (
        <Card sx={ styles.card }>
            <CardHeader
                title={intl.formatMessage({ id: 'upcomingSessionList.title' })}
                sx={ styles.cardHeader }
            >
            </CardHeader>
        
            <CardContent sx={ styles.cardContent }>
            {
                loading ?
                    <CircularProgress /> 
                    :
                    sessions && 
                    <List dense={true}>
                        {
                            sessions?.map( (session, i) => (
                                    <Session 
                                        key={i} 
                                        courses={courses}  
                                        session={session} 
                                        onSessionClick={onSessionClick}
                                        intl={intl} />
                                )
                            )
                        }
                    </List>
            }
            </CardContent>
        </Card>
    );
};

const Session = ({ session, courses, onSessionClick, intl }) => {

    const styles = {
        sessionDayTypo: {
            color: 'black',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 500
        },        
        sessionPaper: {
            textAlign: 'left',
            color: 'black',
            backgroundColor: '#f0f0f0',
            padding: '10px',
            height: '80px',
            alignContent: 'center',
            ':hover': {
              boxShadow: 10
            },  
        },
        sessionContainerDiv: {
            display: 'flex',
            flexDirection: 'row'
        },
        zoomIconButton: {
            marginLeft: '10px',
            backgroundColor: 'white',
            ':hover': {
                backgroundColor: '#eeeeee', //'#909090',
            }
        }
    };
    let course = courses.find(course => course?.courseId === session?.courseId);    
    let sessionStartDay = dateTime2LocalShortDay(session?.startTime, intl.locale); 
    let sessionStartTime = dateTime2LocalHour(session?.startTime, intl.locale);
    let sessionEndTime = dateTime2LocalHour(session?.endTime, intl.locale);
    
    const courseTitle = course?.description?.title ? course.description.title : 'no course title';
    const sessionTitle = session?.title ? session.title : 'no session title';

    let videoConfUrl = null;
    if(session?.sessionLinks){
        const link = session.sessionLinks.find(link => link.link.typeCode=='VIDEO');       
        videoConfUrl = link?.link?.link;
    }

    const handleClickVisio = (event) => {
        event.stopPropagation();
        let alink = document.createElement("a");
        alink.href = videoConfUrl;
        alink.target = '_blank';
        alink.click();
    }

    return ( session?.startTime ? 
        <ListItem dense alignItems='flex-start' key={session?.crsSessionId}>
            <ListItemText 
                sx={{ cursor: 'pointer' }} 
                tabIndex={0}
                onClick={() => {
                    onSessionClick(session.courseId,session.crsSessionId);
                }}
                primary={
                    <>
                        <Typography sx={ styles.sessionDayTypo } >{ sessionStartDay }</Typography>
                        <Paper sx={ styles.sessionPaper }>
                            <div style={ styles.sessionContainerDiv }>                                
                                <SessionSchedule startTime={sessionStartTime} endTime={sessionEndTime} />
                                <SessionTitles courseTitle={courseTitle} sessionTitle={sessionTitle} />
                                { videoConfUrl && 
                                    <IconButton sx={ styles.zoomIconButton }
                                        onClick={handleClickVisio}
                                        tabIndex={0}
                                    >                                        
                                            <VideocamRoundedIcon color='primary' /> 
                                    </IconButton>
                                }      
                            </div>
                        </Paper>
                    </>
                }                
            />
        </ListItem>
        :
        <></>
    )
}

const SessionTitles = ({ courseTitle, sessionTitle }) => {
    const styles = {
        gridContainer: {
            minWidth: 0, 
        },
        gridItem: {
            minWidth: 0, 
        },
        courseTitleTypo: {
            color: '#244F9D',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            whiteSpace: 'nowrap', 
            overflow: 'hidden',
            textOverflow: 'ellipsis'                
        },
        sessionTitleTypo: {
            color: 'black',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 500,
            whiteSpace: 'nowrap', 
            overflow: 'hidden',
            textOverflow: 'ellipsis'    
        }
    }
    return (
        <Grid container sx={ styles.gridContainer } rowSpacing={1} alignContent='center'>
            <Grid item xs={12}  sx={ styles.gridItem }>
                <Tooltip title={courseTitle} placement="top-end" arrow={true}>
                    <Typography id='courseTitle' sx={ styles.courseTitleTypo }>{ courseTitle }</Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sx={ styles.gridItem }>
                <Tooltip title={sessionTitle} placement="bottom-end" arrow={true}>
                    <Typography id='sessionTitle' sx={ styles.sessionTitleTypo }>{ sessionTitle }</Typography>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

const SessionSchedule = ({ startTime, endTime }) => {
    const styles = {
        gridContainer: {
            width: '30px',
            maxWidth: '30px',
            marginRight: '10px',
            alignContent: 'center',
            justifyContent: 'center'
        },
        sessionScheduleTypo: {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400
        },
        tiretTypo: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '11px'
        }
    }
    return (
        <Grid container rowSpacing={0} sx={ styles.gridContainer } >
            <Grid item>
                <Typography sx={ styles.sessionScheduleTypo }>{ startTime }</Typography>
            </Grid>
            <Grid item>
                <Typography sx={ styles.tiretTypo }>-</Typography>
            </Grid>
            <Grid item>
                <Typography sx={ styles.sessionScheduleTypo }>{ endTime }</Typography>
            </Grid>
        </Grid>
    )
}

export default UpcomingSessionList;

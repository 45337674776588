import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {  Card, CardActionArea } from "@mui/material";

import {EDUGAIN_URI} from 'utils/constants/constants';
import {useLocation} from '@gatsbyjs/reach-router';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
    root: {
        with: '100%',
    },

    schoolBlock: {
        width : '100%',
        minHeight: '263px',
    },

    schoolLogo: {
        margin: '0rem 1rem 0.5rem 1rem',
        backgroundSize: 'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        height:'130px',
    },
    
    schoolName : {
        width : '100%',
        height: '93px',
        display:'table',
        lineHeight: '1.2rem'
    },
    schoolNameText : {
        verticalAlign: 'middle',
        display : 'table-cell',
        fontWeight: 600,
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.primary.main,
    }
}))

const buildSAMLLogin = (uri,location) => {
    if (uri && uri.length > 0) {
        let url = EDUGAIN_URI + encodeURI(uri);
        let dest = queryString.parse(location.search)["dest"];
        if(dest){
            url = url + "&dest=" + dest;
         }
        return url;
    }
    return ''
}

const EDUGainEntry = ({pp}) => {
    const classes = useStyles();
    const {img,  name, EDUGainAuthURL, acronym} = pp
    
    const location = useLocation();
    
    return (
        <Card className={classes.root}>
            <CardActionArea component="a"
                href={buildSAMLLogin(EDUGainAuthURL,location)}
                alt={acronym}
                aria-label={name}
                className={classes.schoolBlock} 
            >
                
                <div className={classes.schoolLogo} 
                        style={{ backgroundImage: `url(${img})`}}>
                </div>
               
                <div className={classes.schoolName}>
                    <h3 className={classes.schoolNameText}>{name} </h3>
                </div>
            </CardActionArea>
        </Card>
    )
} 

export default EDUGainEntry

import React, { useCallback, useEffect, useState } from "react";

import { Box, Card, CardActionArea, CardContent, Divider, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Container, Typography } from "@mui/material";
import { useIntl } from 'gatsby-plugin-intl';
import { navigate } from "gatsby-plugin-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";

import CourseLogo from "components/catalog/common/CourseLogo";
import CourseType from "components/catalog/common/CourseType";
import { CATALOG_FIELDS_BY_TYPE } from "utils/constants/courses-constants";
import courseService from "services/api/courseService";
import { GROUP } from "utils/constants/back-office-constants";
import themeMuiV5 from "components/common/ThemeMuiV5";
import { dateTime2LocalShortDay } from "utils/date-utils";


const CourseCardList = ({ courses, isPast}) => {
    const Intl = useIntl();
    return (        
            <Box>
                <Typography 
                    variant="h5"    
                    component="h2"
                    gutterBottom
                >
                    {isPast ? Intl.formatMessage({id: "myCourses.courseCardList.past.title"}) : Intl.formatMessage({id: "myCourses.courseCardList.future.title"})}
                </Typography>
                { courses?.length > 0 ?
                    <Container sx={{ marginBottom: themeMuiV5.spacing(4) }}>
                        {courses.map((course, index) => {
                            return <CourseCard key={index} course={course} isPast={isPast}/>
                        })}
                    </Container>
                    : 
                    isPast ? 
                        <></> 
                        : 
                        <NoFutureCoursesMessage/>
                }
            </Box>
    );
}

const NoFutureCoursesMessage = () => {
    const intl = useIntl();
    return (
        <Grid container item direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography component="h2">
                            {intl.formatMessage({id: "header.disclaimer"})}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
} 


const CourseCard = ({ course, isPast }) => {
    const isMobile = useMediaQuery(themeMuiV5.breakpoints.down('lg'));
    const orientDivider = isMobile ? 'horizontal' : 'vertical';
    const styles = {
        divider: {
            height: isMobile ? 'auto': '100%',
            width: isMobile ? '100%': 'auto'          
        }
    }
    return (
        <Card
            sx={{ marginBottom: 2 }}
        >
        <CardActionArea
            onClick={() => {
                navigate(`/courses/${course.courseId}`);
            }}
        >
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={7.8}>
                        <CourseCardTitleDescription course={course}/>
                    </Grid>
                    <Grid item xs={12} lg={0.2} sx={{
                        display:'flex',
                        justifyContent: 'center'
                    }}>
                        <Divider 
                                orientation={orientDivider}
                                flexItem
                                aria-hidden="true"
                                sx={ styles.divider } 
                        />             
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <CourseCardInfos course={course} isPast={isPast}/>
                    </Grid>                    
                </Grid>
            </CardContent>
        </CardActionArea>

        </Card>
    );
};


const CourseCardInfos = ({ course, isPast }) => {
    return (
        <Box>            
            <CourseLogo course={course}/>           
            <CourseType courseType={course.typeCode} courseSubType={course.subtype} isEngage={course.isEngage} pageFields={CATALOG_FIELDS_BY_TYPE(course.typeCode)}/>
            <CoursePeriod coursePeriod={course.semester}></CoursePeriod>         
            <CourseSessionDate isPast={isPast} lastSessionDate={course.lastSessionDate} nextSessionDate={course.nextSessionDate} ></CourseSessionDate>
        </Box>
    );
}

const CourseCardTitleDescription = ({ course }) => {   
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Typography 
                variant="h6"    
                component="h3"
                gutterBottom
            >
                { course?.description?.title }
            </Typography>
            <Typography
                variant="body1"
                component="p"
                gutterBottom 
                textAlign={'justify'}               
            >
                { course?.description?.shortDesc }
            </Typography>        
        </Box>
    );
}


const CourseSessionDate = ({ isPast, lastSessionDate, nextSessionDate }) => {
    const intl = useIntl();
    let lastSessionDay = dateTime2LocalShortDay(lastSessionDate, intl.locale);
    let nextSessionDay = dateTime2LocalShortDay(nextSessionDate, intl.locale);      
    return (
    <>
    {
        lastSessionDate || nextSessionDate ?
        <Typography variant="body1" paragraph>
            <FontAwesomeIcon
                icon={faClock}
                color={themeMuiV5.palette.primary.main}
            />
            <Typography component="span"
                sx={{ marginLeft: themeMuiV5.spacing(1) }}
            >
                { intl.formatMessage({ id: isPast ? "course.session.last" : "course.session.next" }) }
                {`: ${isPast ? lastSessionDay : nextSessionDay}`}
            </Typography>    
        </Typography>
        :
        <></>
    }
    </>
    );
}

const CoursePeriod = ({ coursePeriod }) => {
    const intl = useIntl();
    return (
    <Box>
    {   
        coursePeriod ?
        <Typography variant="body1" paragraph>
            <FontAwesomeIcon
                icon={faCalendarAlt}
                color={themeMuiV5.palette.primary.main}
            />
            <Typography component="span"
                        sx={{marginLeft: themeMuiV5.spacing(1),}}
            >
                {intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.period,
                        coursePeriod
                    ),
                })}
            </Typography>
        </Typography> 
        :
        <></>
    }
    </Box>
    );
}

export default CourseCardList;